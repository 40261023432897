<template>
  <div>

  </div>
</template>

<script>
import {getUrlParam, getContentParam} from "../../../utils/location";
import SystemConfig from "../../../config/SystemConfig";
import users from "@/request/users";

export default {
  name: "jump",
  data() {
    return {
      ask: new users(),
    }
  },
  mounted() {
  },
  created() {
    const param = getUrlParam()
    if (param.code == null) {
      let time = new Date().getTime();
      this.$storageHandler.setSessionStorage(time, param.redirect_uri);
      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + SystemConfig.weChatSso.APPID +
          '&redirect_uri=' + encodeURIComponent(window.location.origin + window.location.pathname) +
          '&response_type=code&scope=snsapi_base&state=' + time + '#wechat_redirect';
    } else {
      this.ask.wechatSso({code: param.code}).then(res => {
        if (res.code === 'success') {
          this.resetSetItem("loginUser", JSON.stringify(res.msg));
          this.$store.commit("user/setIsLogin", true)
          this.$store.commit("user/setUserInfo", res.msg)
          // setTimeout(() => {
          if ("weChatSubscribe" === param.redirect_uri) {
            window.location.href = window.location.origin + "/expand/weChat/subscribe"
          } else {
            let content = this.$storageHandler.getSessionStorage(param.state);
            this.$storageHandler.removeSessionStorage(param.state)
            let decodeParam = decodeURIComponent(String(content))
            let list = getContentParam(decodeParam)
            this.$router.push({
              name: list[0]['page'],
              query: list[1]
            })
          }
          // }, 1000);
        }
        // 需要绑定
        else if (res.code === 'fail') {
          this.$router.push({
            name: 'login',
            query: {
              wechatCode: res.msg
            }
          })
        } else {
          window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + SystemConfig.weChatSso.APPID +
              '&redirect_uri=' + encodeURIComponent(window.location.origin + "/expand/weChat/jump?redirect_uri=" + param.state) +
              '&response_type=code&scope=snsapi_base&state=' + param.state + '#wechat_redirect';
        }
      });
      // this.ask.getWxOpenId({code: param.code}).then(res => {
      //   if (res.code === 'success') {
      //     // this.$store.commit("user/setOpenId", res.msg)
      //     this.$storageHandler.setSessionStorage("openId", res.msg);
      //     if ("weChatSubscribe" === param.redirect_uri) {
      //       window.location.href = window.location.origin + "/expand/weChat/subscribe"
      //     } else {
      //       this.$router.push({
      //         name: param.state
      //       })
      //     }
      //   } else {
      //     this.$message({
      //       showClose: true,
      //       duration: 0,
      //       message: '服务器错误!',
      //       type: 'error'
      //     });
      //   }
      // }).catch(() => {
      //   this.$message({
      //     showClose: true,
      //     duration: 0,
      //     message: '服务器错误!',
      //     type: 'error'
      //   });
      // })
    }
  }
}
</script>

<style scoped>

</style>