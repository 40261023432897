export function getUrlParam() {
    var url = location.search;
    var theRequest = {};
    if (url.indexOf('?') !== -1) {
        var str = url.substr(1);
        var strs = str.split('&');
        for (var i = 0; i < strs.length; i++) {
            theRequest[strs[i].split('=')[0]] = (strs[i].split('=')[1]);
        }
    }
    return theRequest;
}

export  function getContentParam(content) {
    let param = []
    let query=  {};
    let list = content.split('?');
    for (let i = 0; i < list.length; i++) {
        if (i === 0) {
            param.push({
                "page": list[0]
            });
            continue;
        }
        let indexI = list[i].indexOf("=");
        query[list[i].substring(0, indexI)] = list[i].substring(indexI + 1)
    }
    param.push(query)
    return param
}